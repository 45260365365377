import _ from 'lodash';

export const AVAILABLE_COLOURS_ENUM = {
	PRIMARY_LIGHT: 'primary-light',
	PRIMARY_NORMAL: 'primary-normal',
	PRIMARY_CONTRAST: 'primary-contrast',
	SECONDARY_GREEN_LIGHT: 'secondary-green-light',
	SECONDARY_GREEN_CONTRAST: 'secondary-green-contrast',
	SECONDARY_BLUE_LIGHT: 'secondary-blue-light',
	SECONDARY_BLUE_CONTRAST: 'secondary-blue-contrast',
	SECONDARY_PINK_LIGHT: 'secondary-pink-light',
	SECONDARY_PINK_CONTRAST: 'secondary-pink-contrast',
	SECONDARY_PURPLE_LIGHT: 'secondary-purple-light',
	SECONDARY_PURPLE_CONTRAST: 'secondary-purple-contrast',
	SECONDARY_RED_LIGHT: 'secondary-red-light',
	SECONDARY_RED_CONTRAST: 'secondary-red-contrast',
	SECONDARY_GRAY_LIGHT: 'secondary-gray-light',
	SECONDARY_GRAY_CONTRAST: 'secondary-gray-contrast',
	SECONDARY_WHITE: 'secondary-white',
	SECONDARY_BLACK: 'secondary-black',
} as const;

export const SHORT_HAND_COLOURS_ENUM = {
	PRIMARY: 'primary',
	SECONDARY_GREEN: 'secondary-green',
	SECONDARY_BLUE: 'secondary-blue',
	SECONDARY_PINK: 'secondary-pink',
	SECONDARY_PURPLE: 'secondary-purple',
	SECONDARY_RED: 'secondary-red',
	SECONDARY_GRAY: 'secondary-gray',
} as const;

export const AVAILABLE_COLOURS = _.values(AVAILABLE_COLOURS_ENUM);

export const SHORT_HAND_COLOURS = _.values(SHORT_HAND_COLOURS_ENUM);

export type TColours = (typeof AVAILABLE_COLOURS)[number];
export type TShortHandColours = (typeof SHORT_HAND_COLOURS)[number];

export const colourHexCodes: Record<TColours, string> = {
	'primary-light': 'ffe67e',
	'primary-normal': 'ffbc3b',
	'primary-contrast': 'ff7639',

	'secondary-green-light': 'ceefd1',
	'secondary-green-contrast': '6cb039',
	'secondary-blue-light': 'd9e7ff',
	'secondary-blue-contrast': '36bcd5',
	'secondary-pink-light': 'ffdcf3',
	'secondary-pink-contrast': 'ea6aff',
	'secondary-purple-light': 'd8d2ff',
	'secondary-purple-contrast': '8d6af3',
	'secondary-red-light': 'faebd4',
	'secondary-red-contrast': 'f67a6c',
	'secondary-gray-light': 'd9d9d9',
	'secondary-gray-contrast': '898989',
	'secondary-white': 'ffffff',
	'secondary-black': '1e1e1e',
};
